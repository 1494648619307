<template>
  <div class="same-goods">
    <van-swipe
      :autoplay="0"
      :height="180"
      :show-indicators="false"
      :width="150"
    >
      <van-swipe-item
        :key="index"
        @click="showInfo({id: item.id})"
        v-for="(item, index) in list"
      >
        <div class="ph-5">
          <van-image
            :src="item.titlepic"
            fit="fill"
            height="140"
            width="140"
          ></van-image>
          <div
            class="same-title"
            v-text="item.title"
          ></div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
Vue.use(Swipe).use(SwipeItem);
export default {
  name: "goodsSameClass",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.same-goods {
  padding: 10px 5px;
}
.same-goods .same-title {
  font-size: 12px;
  line-height: 14px;
  padding: 5px;
}
.same-goods .same-title {
  width: 140px;
  height: 140px;
}
</style>