<template>
  <van-cell-group title="规格">
    <template v-for="(sku, index) in skus">
      <!-- Sku -->
      <van-cell :icon="currentSkuId === sku.sku_id ? 'arrow' : ''" :key="index" @click="selectSku(sku)" is-link title-style="width: 80%">
        <div>
          {{sku.sku_title}}
          <span class="light-bg pl-15 danger bb" style="position: absolute; right: 0">
            <van-icon name="gem-o"></van-icon>
            {{sku.sku_buyfen}}
          </span>
        </div>
      </van-cell>
    </template>
  </van-cell-group>
</template>

<script>
export default {
  name: "SkuList",
  props: {
    skus: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: function () {
    return {
      collapseActive: 0,
      currentSkuId: 0,
    };
  },
  methods: {
    selectSku: function (sku) {
      this.currentSkuId = sku.sku_id;
      this.$emit("onselect", sku);
    },
  },
};
</script>
