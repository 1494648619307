<template>
  <div>
    <sku-list :skus="skus" @onselect="selectSku" />
    <!-- 点击sku弹出下载链接 -->
    <van-action-sheet
      :title="curSku.sku_title"
      close-on-popstate
      closeable
      lock-scroll
      round
      style="
        width: 100%;
        max-width: 640px;
        height: 88%;
        background-color: #f2f3f5;
      "
      v-model="downPopupVisible"
    >
      <!-- 已购买,显示网盘信息 -->
      <sku-down-list
        :sku="curSku"
        @close="downPopupVisible = false"
      ></sku-down-list>
    </van-action-sheet>
  </div>
</template>

<script>
import SkuList from "./SkuList.vue";
import SkuDownList from "./SkuDownList.vue";
import Vue from "vue";
import { ActionSheet } from "vant";

Vue.use(ActionSheet);

export default {
  name: "GoodsSkus",
  components: {
    SkuDownList,
    SkuList,
  },
  props: {
    skus: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      curSku: {},
      downInfo: {},
      downPopupVisible: false,
    };
  },
  methods: {
    selectSku: function (sku) {
      this.curSku = { ...sku };
      console.log("curSku", this.curSku);

      this.downPopupVisible = true;
    },
  },
};
</script>
