<template>
  <div v-if="list && list.length">
    <van-card
      :desc="row.newstext"
      :key="index"
      :title="row.title"
      @click="cardClick(row)"
      v-for="(row, index) in list"
      class="mb-5"
    >
      <template #title>
        <h3 class="primary" v-html="row.title"></h3>
      </template>
      <template #num>
        <div>
          <van-icon class="primary mr-5" name="gem-o"></van-icon>
          <span class="danger">{{row.buyfen}}</span>
        </div>
      </template>
      <template #thumb>
        <van-image :error-icon="noPic" :src="row.titlepic" class="w100 thumbnail" lazy-load>
          <template v-slot:loading>
            <van-loading size="48" type="spinner" vertical>加载中...</van-loading>
          </template>
          <template v-slot:error>
            <van-image :src="noPic" class="w100" />
          </template>
        </van-image>
      </template>
      <template #desc>
        <div v-html="row.newstext"></div>
      </template>
    </van-card>
  </div>
  <van-empty description="暂无信息" v-else />
</template>

<script>
import Vue from "vue";
import noPic from "@/assets/noPic.jpg";
import { Empty, Lazyload, Card, Image, Loading } from "vant";
Vue.use(Empty)
  .use(Lazyload)
  .use(Card)
  .use(Image)
  .use(Loading);

export default {
  props: ["list"],
  data() {
    return {
      noPic
    };
  },
  methods: {
    cardClick(info) {
      const params = {
        id: info.id
      };
      this.showInfo(params);
      // this.$emit('onClick', info)
    }
  }
};
</script>

<style scoped>
.goods-grid {
  background-color: #ffffff;
  padding: 0 5px;
}

.goods-grid .col {
  padding: 6px;
  margin-top: 5px;
}

.goods-grid .van-image {
  border: 1px solid #000;
  cursor: pointer;
}

.goods-grid .goods-name {
  padding: 5px;
  height: 36px;
  font-size: 12px;
  cursor: pointer;
}

.goods-grid .goods-price {
  width: 100%;
  text-align: right;
  padding: 3px 20px;
  color: #f60;
}

.goods-grid .error-img {
  min-height: 100px;
}
</style>
