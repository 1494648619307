<template>
  <div>
    <div>
      <van-sticky>
        <div class="video-player" v-show="!isError">
          <d-player
            :options="options"
            :playsinline="playsinline"
            @dblclick.native="onDbClick"
            @ended="onEnded"
            @pause="onPause"
            @play="onPlay"
            @timeupdate="onTimeupdate"
            id="player"
            ref="player"
            webkit-playsinline
            x-webkit-airplay="allow"
            x5-playsinline
            x5-video-player-type="h5"
          ></d-player>
        </div>
      </van-sticky>
      <div class="errorTip" v-show="isError">
        <p>视频出错了！</p>
      </div>
    </div>
    <div v-if="list.total">
      <!-- 横向列表 -->
      <div class="h-list">
        <div class="h-list-box">
          <div class="h-list-layout">
            <template v-for="(v, index) in urls">
              <van-button :disabled="v.is_fz == 1" :key="index" :type="curId===v.id?'primary':'default'" @click="videoClick(v)" class="h-list-item ellipsis--l2" plain style="flex-basis: 150px" v-if="v.id">
                <span v-text="v.title"></span>
              </van-button>
            </template>
          </div>
        </div>
        <span @click="vlistShow=true" class="h-list-btn">
          <van-icon name="arrow-down" />
        </span>
      </div>
      <!-- 列表弹窗 -->
      <van-action-sheet
        :overlay="false"
        :style="{ 'max-width': '640px', width: '100%', height: popupHeight }"
        class="grey-bg"
        close-on-popstate
        closeable
        get-container="body"
        position="bottom"
        ref="listPop"
        v-model="vlistShow"
      >
        <div class="vlist-header">
          <div class="ph-15 pv-5">
            <van-row>
              <van-col span="4">
                <img :src="book.titlepic" alt class="header-thumb" />
              </van-col>
              <van-col span="20">
                <van-row>
                  <van-col span="22">
                    <div class="pb-5 header-title van-ellipsis">{{book.title}}</div>
                  </van-col>
                  <van-col @click="vlistShow=false" span="2">
                    <van-icon name="cross" size="20" />
                  </van-col>
                </van-row>
                <van-row>
                  <van-col class="pv-10" span="12">
                    ￥
                    <span class="danger fs150" v-text="book.price_format"></span>
                  </van-col>
                  <van-col class="tr" span="12">
                    <van-button @click="buyBook()" size="small" type="warning">购 买</van-button>
                  </van-col>
                </van-row>
              </van-col>
            </van-row>
          </div>
        </div>
        <van-cell-group style="margin-top: 75px" title=" ">
          <van-cell :key="index" :title="v.title" :title-class="curId==v.id?'success':''" @click="videoClick(v)" v-for="(v, index) in list.v">
            <template #right-icon>
              <div :class="curId==v.id?'success':''">
                <span class="mr-5" v-text="v.tip"></span>
                <van-icon name="play-circle-o" v-if="v.np" />
              </div>
              <van-icon color="#FAAB0C" name="gem-o" v-if="!v.np" />
            </template>
          </van-cell>
        </van-cell-group>
        <div class="popTips">
          <p class="danger bb">视频播放说明：</p>
          <p>[试看]视频可以直接观看;</p>
          <p>购买[教材]后教材内全部在线视频可播放完整版;</p>
        </div>
      </van-action-sheet>
      <!-- 播放视频确认 -->
      <van-action-sheet :title="'播放 '+prePlay.title" v-model="prePlayVisible">
        <div class="p-30">购买教材后可观看全部完整版视频，或购买当前完整版视频</div>
        <van-row gutter="5">
          <van-col span="8">
            <van-button :disabled="!prePlay.url" @click="doPlay(prePlay)" block type="primary">播放试看</van-button>
          </van-col>
          <van-col span="8">
            <van-button @click="getData(prePlay.id)" block type="warning">完整版视频</van-button>
          </van-col>
          <van-col span="8">
            <van-button @click="buyBook()" block type="danger">购买教材</van-button>
          </van-col>
        </van-row>
        <div class="p-30"></div>
      </van-action-sheet>
      <!-- 购买视频弹窗 -->
      <van-action-sheet v-model="payfordownconfirm">
        <van-cell value-class="tc">购买视频：{{vinfo.title}}</van-cell>
        <h3 class="pt-20 tc">本视频将扣除您 {{vinfo.buyfen_format}}</h3>
        <h3 class="pb-20 tc" v-if="isLogin">当前有 {{$store.state.user.userfen}}</h3>
        <h3 class="pb-20 tc" v-else>您需要先登陆</h3>
        <div class="ph-15">
          <van-row class="mb-5" gutter="5">
            <van-col :span="8">
              <van-button @click="payfordownconfirm=false" block round>取消</van-button>
            </van-col>
            <van-col :span="16" v-if="!isLogin">
              <van-button @click="$router.push({name:'login'})" block plain round type="primary">登 陆</van-button>
            </van-col>
            <van-col :span="8" v-if="isLogin">
              <van-button @click="$router.push({name: 'buyfen'})" block plain round type="warning">获取点数</van-button>
            </van-col>
            <van-col :span="8" v-if="isLogin">
              <van-button @click="doPayfordown" block plain round type="primary">确定</van-button>
            </van-col>
          </van-row>
        </div>
        <p class="p-20"></p>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import { hasHistory } from "@/libs/tools";
import Vue from "vue";
import {
  Cell,
  Button,
  Badge,
  Icon,
  CellGroup,
  Row,
  Col,
  Card,
  Sticky,
  ActionSheet,
} from "vant";
Vue.use(Cell)
  .use(Icon)
  .use(CellGroup)
  .use(Badge)
  .use(Button)
  .use(Sticky)
  .use(Row)
  .use(Col)
  .use(Card)
  .use(ActionSheet);
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
import logo from "@/assets/75pop.png";

export default {
  name: "videoList",
  components: { "d-player": VueDPlayer },
  props: ["list", "book"],
  data() {
    return {
      curId: 0,
      isReady: false,
      isError: false,
      vlistShow: false,
      vlist: this.list.v,
      vinfo: {},
      prePlay: {},
      prePlayVisible: false,
      popupHeight: "75%",
      showNextNotice: false,
      payfordownconfirm: false,
      container: null,
      options: {
        container: document.getElementById("player"), //播放器容器
        mutex: false, //  防止同时播放多个用户，在该用户开始播放时暂停其他用户
        theme: "#b7daff", // 风格颜色，例如播放条，音量条的颜色
        loop: false, // 是否自动循环
        lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
        screenshot: true, // 是否允许截图（按钮），点击可以自动将截图下载到本地
        hotkey: true, // 是否支持热键，调节音量，播放，暂停等
        preload: "auto", // 自动预加载
        volume: 0.7, // 初始化音量
        playbackSpeed: [0.5, 0.75, 1, 1.25, 1.5, 2, 3], //可选的播放速度，可自定义
        logo: logo, // 在视频左上角上打一个logo
        video: {
          url: "", // 播放视频的路径
          pic: this.book.titlepic,
        },
      },
      player: null,
    };
  },
  watch: {
    vid() {
      this.init();
    },
  },
  computed: {
    playsinline() {
      var ua = navigator.userAgent.toLocaleLowerCase();
      //x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        return false;
      } else {
        //ios端
        return true;
      }
    },
    urls() {
      return this.list.p;
    },
    vid() {
      return this.$route.params.vid;
    },
    isWeixin() {
      return this.$store.state.isWeixin;
    },
  },
  methods: {
    /** 点击一个视频 */
    videoClick(info) {
      if (info.url && info.np) {
        this.doPlay(info);
      } else {
        this.prePlay = info;
        this.prePlayVisible = true;
      }
    },
    /** 双击 播放/暂停 */
    onDbClick() {
      if (this.player) this.player.toggle();
    },
    /** 一个视频播放完成 */
    onEnded() {
      const next = this.getNext();
      if (next) {
        this.videoClick(next);
      }
    },
    /** 一个视频开始播放 */
    onPlay() {
      const cur = this.urls[this.curId];
      if (cur && cur.is_fz != 1) {
        this.player.notice(cur.title);
      }
      this.setPopupHeight();
    },
    /** 暂停 */
    onPause() {},
    /** 正在播放中 */
    onTimeupdate() {
      const v = this.player.video;
      if (v.duration - v.currentTime <= 10 && !this.showNextNotice) {
        const next = this.getNext();
        this.showNextNotice = true;
        if (next.url) this.player.notice(`即将播放：${next.title}`, 10000);
      }
    },
    getNext() {
      let curId = this.curId,
        urls = this.urls,
        keys = Object.keys(urls),
        index = keys.indexOf(curId),
        len = keys.length;
      index = index >= len - 1 ? 0 : index + 1;
      let nextId = keys[index];
      return urls[nextId];
    },
    /** 播放一个视频 */
    doPlay(info) {
      this.prePlayVisible = false;
      const dp = this.player;
      if (!info) {
        let urls = this.urls;
        var curId = this.curId;
        info = urls[curId];
        if (!info || !info.url) {
          dp.notice("无可播放文件");
          return false;
        }
      } else {
        this.curId = info.id;
      }

      dp.switchVideo({
        url: info.url,
        pic: info.pic,
      });
      dp.play();
      this.showNextNotice = false; // 重置 即将下一曲 提醒
      this.setPopupHeight();
    },
    getData(id) {
      this.prePlayVisible = false;
      this.$http.get("e/?m=v", { id }).then((res) => {
        if (res.code) {
          if (hasHistory()) {
            this.$router.go(-1);
          } else {
            this.$router.push({ name: "index" });
          }
        } else {
          const { info } = res.data;
          this.vinfo = info;
          if (info.error) {
            this.payfordownconfirm = true;
          } else {
            this.isReady = true;
            this.doPlay(info);
          }
        }
      });
    },
    setPopupHeight() {
      let bodyHeight = document.body.clientHeight;
      let playerHeight = document.getElementById("player").offsetHeight;
      let h = bodyHeight - playerHeight;
      if (h < bodyHeight / 2) h = bodyHeight / 2;
      this.popupHeight = h + "px";
    },
    doPayfordown() {
      const id = this.info.id;
      const [type, index] = this.curIndex;
      this.$http.post("e/?m=kf", { id, c: 1 }).then((res) => {
        if (res.code === 0) {
          this.payfordownconfirm = false;
          this.getData(type, index);
        }
      });
    },
    buyBook() {
      this.prePlayVisible = false;
      this.$emit("onbuy");
    },
    init() {
      let first,
        urls = this.urls,
        vid = this.vid;
      // 如果有可播放列表
      if (urls && Object.keys(urls).length) {
        if (vid) {
          console.log("vid", vid, urls[vid]);
          // 有vid参数，自动播放
          if (urls[vid]) {
            first = urls[vid];
            console.log(first);
            if (first.url) this.doPlay(first);
          } else {
            this.getData(vid);
          }
        } else {
          // 否则选中第一个可播放的视频
          first = urls[Object.keys(urls)[0]];
          if (first.url) {
            this.curId = first.id;
            this.player.switchVideo({
              url: first.url,
              pic: this.book.titlepic,
            });
          }
        }
      }
    },
  },
  mounted() {
    this.container = this.$refs.listPop;
    this.player = this.$refs.player.dp;
    // 调整列表高度
    this.init();
    this.setPopupHeight();
  },
};
</script>

<style>
.dplayer-logo {
  left: auto;
  right: 20px;
  max-width: 70px;
}
.dplayer-menu.dplayer-menu-show {
  display: none !important;
}
</style>

<style scoped>
.h-list {
  background: #fff;
  padding: 8px;
  border-top: 1px solid #e0e0e0;
  position: relative;
}
.h-list-box {
  overflow: hidden;
}
.h-list-layout {
  display: flex;
  overflow: auto;
  justify-content: space-between;
}
.h-list-item {
  position: relative;
  padding: 10px;
  flex-basis: 150px;
  flex-shrink: 0;
  margin-right: 5px;
}
.v-list-item {
  position: relative;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.h-list-item.active,
.v-list-item.active {
  color: #07c160;
  border-color: #07c160;
}
.h-list-btn {
  position: absolute;
  background-color: #fff;
  padding: 12px 20px;
  right: 0;
  top: 7px;
  box-shadow: 3px 0 5px 5px #fff;
}
.v-tag {
  position: absolute;
  right: 1px;
  top: 1px;
}
.popTips {
  padding: 15px 30px;
  letter-spacing: 2px;
  background-color: #f5ca6c80;
}
.vlist-header {
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 10px #e0e0e0;
}
.vlist-header .header-title {
  font-size: 16px;
}
.vlist-header .header-thumb {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
}
</style>