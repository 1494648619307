<template>
  <div class="pb-30">
    <!-- 显示提示 -->
    <div v-if="sku.no_paid_msg" class="notice">
      <p v-html="sku.no_paid_msg"></p>
    </div>

    <template v-for="(info, index) in sku.downs">
      <!-- 有网盘链接 -->
      <van-cell-group :key="index" v-if="sku.code == 1">
        <template #title>
          <h3>{{ info.wangpan_title }}</h3>
        </template>
        <van-cell
          :label="info.wangpan_desc"
          @click="doCopy(info)"
          center
          is-link
        >
          <div slot="title">
            <div v-html="info.downmsg"></div>
          </div>
        </van-cell>
        <van-cell
          @click="previewImage(info.qrcodeurl)"
          icon="qr"
          is-link
          v-if="info.qrcodeurl"
          value="小程序码"
        ></van-cell>
      </van-cell-group>
      <!-- 无链接 提示 -->
      <van-cell-group :key="index" v-if="sku.code === 0">
        <van-cell>
          <div class="mv-30" v-html="info.downerror"></div>
        </van-cell>
      </van-cell-group>
    </template>

    <!-- 如果没有购买过，显示下载提示 -->
    <van-cell-group v-if="!sku.is_paid">
      <!-- 购买Button -->
      <van-cell>
        <van-button @click="doPayfordown(sku)" block class="tc" type="warning">
          购 买 ( 点数: {{ sku.sku_buyfen }} <van-icon name="gem-o"></van-icon>)
        </van-button>
      </van-cell>
      <van-notice-bar
        :scrollable="false"
        color="#1989fa"
        background="#ecf9ff"
        left-icon="info-o"
        :text="`点数充值比例: ${sku.sku_buyfen} 点 = ${sku.sku_buyfen / 10}元`"
      ></van-notice-bar>
      <van-cell value-class="tc gray" v-if="isLogin"
        >您当前点数: {{ user.userfen }}
        <van-icon name="gem-o" class="danger"></van-icon
      ></van-cell>
      <van-cell
        v-else
        title="您未登陆"
        value="去登陆"
        is-link
        :to="{ name: 'login' }"
      ></van-cell>
      <van-cell value-class="tc gray"></van-cell>
    </van-cell-group>
    <!-- 底部固定内容 -->
    <van-cell-group>
      <van-cell>
        <van-button
          @click="reportVisible = !reportVisible"
          icon="warning"
          plain
          size="small"
          type="warning"
          >报错</van-button
        >
        <span class="fr">
          <van-button
            :to="{ name: 'downbak' }"
            class="mr-10"
            icon="balance-list-o"
            plain
            size="small"
            type="info"
            >查看我的全部下载记录</van-button
          >
          <van-button
            :to="{ name: 'buyfen' }"
            icon="gem-o"
            size="small"
            type="warning"
            >充值点数</van-button
          >
        </span>
      </van-cell>
    </van-cell-group>
    <transition name="van-slide-down">
      <report :info="sku" v-show="reportVisible" />
    </transition>
    <van-cell-group title="下载电子版说明">
      <van-cell>
        <van-tag class="mr-5" type="primary">1</van-tag>
        <span>标价为点数，1元可充值10点；</span>
      </van-cell>
      <van-cell>
        <van-tag class="mr-5" type="primary">2</van-tag>
        <span>默认发网盘分享,也可联系站长使用其他方式；</span>
      </van-cell>
      <van-cell>
        <div>
          <van-tag class="mr-5" type="primary">3</van-tag>
          <span>如需将电子版文件存进U盘寄送,联系站长补U盘和运费价格；</span>
        </div>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import Report from "../report.vue";

import { ActionSheet, Tag, ImagePreview, NoticeBar } from "vant";

Vue.use(ActionSheet).use(VueClipboard).use(Tag).use(NoticeBar);

export default {
  name: "SkuDownList",
  components: { Report },
  props: {
    sku: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tabIndex: 0,
      reportVisible: false,
      openConfirm: false,
    };
  },
  computed: {
    isloading() {
      return this.$store.state.ajaxLoading;
    },
    user() {
      return this.$store.state.user || {};
    },
  },
  methods: {
    previewImage: function (src) {
      ImagePreview({
        images: [src],
        closeable: true,
      });
    },
    doPayfordown: function () {
      const { portal_id: id, sku_id, sku_buyfen } = this.sku;
      this.$dialog
        .confirm({
          title: "提示",
          message: `确认将扣除您${sku_buyfen}点数`,
        })
        .then(() => {
          this.$http.post("excharge_coin", { id, sku_id }).then((res) => {
            if (res.code === 1) {
              this.$store.commit("reloadPageData", "info");
              this.$emit("close");
            }
          });
        })
        .catch(() => {});
    },
    doCopy: function (info) {
      if (info && info.downmsg) {
        this.$copyText(this.sku.sku_content + " " + info.downmsg).then(
          () => {
            this.$toast.success("复制成功");
          },
          () => {
            this.$toast.fail("您的设备不支持");
          }
        );
      } else {
        this.$dialog.alert({ message: "未添加网盘链接,请联系客服补充" });
      }
    },
  },
};
</script>

<style scoped>
.notice {
  position: relative;
  text-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 16px;
  color: #ed6a0c;
  font-size: 14px;
  line-height: 24px;
  background-color: #fffbe8;
}
</style>
