<template>
  <div class="page">
    <van-skeleton :loading="!info" :row="20" title>
      <div v-if="info">
        <div v-if="vlist.total">
          <van-sticky>
            <video-list :book="info" :list="vlist" ref="videoList"></video-list>
          </van-sticky>
        </div>
        <div class="goods-img" v-else>
          <van-image :src="info.titlepic || 'http://75pop.com/images/no_picture.gif'" class="w100 thumbnail" lazy-load>
            <template #loading>
              <van-loading size="48" type="spinner" vertical>加载中...</van-loading>
            </template>
            <template #error>
              <van-image class="w100" src="http://75pop.com/images/no_picture.gif" />
            </template>
          </van-image>
        </div>

        <van-cell>
          <van-tag slot="icon">{{ info.productno || "" }}</van-tag>
          <div class="ph-5" slot="title">{{ info.title || "" }}</div>
          <div class="ph-5" slot="label">{{info.filetype}}</div>
          <van-icon :class="info.hasFav?'danger':'gray'" :name="info.hasFav?'star':'star-o'" @click="fava(info)" size="26" slot="right-icon"></van-icon>
        </van-cell>
        <van-cell :value="info.update_time" title="更新时间"></van-cell>

        <goods-skus :skus="skus"></goods-skus>

        <van-cell-group title="相关推荐">
          <same-goods :list="otherlink"></same-goods>
        </van-cell-group>

        <van-cell-group title="详细介绍">
          <div class="desc" v-html="info.newstext" v-if="info.newstext"></div>
          <div class="desc" style="padding: 50px auto" v-else>
            <van-empty description="暂无详细介绍，请联系站长更新" />
          </div>
        </van-cell-group>

        <van-action-sheet style="height: 65%; max-width: 640px" title="选择规格" v-model="actionVisible">
          <goods-skus :skus="skus"></goods-skus>
        </van-action-sheet>
        <van-goods-action safe-area-inset-bottom style="max-width: 640px">
          <van-goods-action-icon icon="home-o" text="首页" to="/"></van-goods-action-icon>
          <van-goods-action-icon :to="{ name: 'kefu' }" icon="chat-o" text="客服"></van-goods-action-icon>
          <van-goods-action-icon :to="{ name: 'goodsList' }" icon="bars" name="list">列表</van-goods-action-icon>
          <van-goods-action-icon :to="{ name: 'login' }" icon="user-o" name="user" v-if="!isLogin">登陆</van-goods-action-icon>
          <van-goods-action-button @click="actionVisible = !actionVisible" text="下载电子版" type="danger"></van-goods-action-button>
        </van-goods-action>
      </div>
    </van-skeleton>
  </div>
</template>

<script>
import Vue from "vue";
import goodsList from "../components/goodsList.vue";
import sameGoods from "./goodsSameClass.vue";
import recommend from "../components/recommend.vue";
import videoList from "../components/videos/index.vue";
import goodsSkus from "./skus/index.vue";
import { hasHistory } from "@/libs/tools";
import wx from "weixin-js-sdk";
import {
  Skeleton,
  Image,
  Collapse,
  CollapseItem,
  Sticky,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  ActionSheet,
  Toast,
  Empty,
} from "vant";

Vue.use(Image)
  .use(Skeleton)
  .use(Collapse)
  .use(CollapseItem)
  .use(Sticky)
  .use(GoodsAction)
  .use(GoodsActionIcon)
  .use(GoodsActionButton)
  .use(ActionSheet)
  .use(Empty);

export default {
  name: "goodsIndex",
  components: {
    goodsList,
    sameGoods,
    recommend,
    goodsSkus,
    videoList,
  },
  props: {
    id: {
      type: [Number, String],
    },
    kw: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      isloading: false,
      actionVisible: false,
      selectSpecId: 0,
      info: null,
      vlist: {},
      skus: [],
      otherlink: {},
    };
  },
  computed: {
    cartNumber() {
      return this.$store.state.cart ? this.$store.state.cart.total : 0;
    },
    reload: function () {
      return this.$store.state.pageDataReload.info;
    },
  },
  watch: {
    id: function () {
      this.getData();
    },
    reload: function () {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.$toast.loading("加载中");
      const requestParam = {
        url: "item-" + this.id,
        method: "get",
        params: {
          kw: this.kw,
        },
      };
      this.request(requestParam).then((res) => {
        if (!res.code) {
          if (hasHistory()) {
            this.$router.go(-1);
          } else {
            this.$router.push({
              name: "index",
            });
          }
        } else {
          this.makeData(res);
        }
        Toast.clear();
      });
    },
    makeData(res) {
      const { info, vlist, otherlink, skus } = res.data;
      this.info = info;
      this.skus = skus;
      this.vlist = vlist;
      this.$store.commit("setPageTitle", info.title);
      this.otherlink = otherlink;
      // wx.config(signpackage);
      const wxParam = {
        title: info.title, // 分享标题
        desc: "", // 分享描述
        imgUrl: info.titlepic || "http://75pop.com/images/no_picture.gif", // 分享图标
        type: "link", // 分享类型,music、video或link，不填默认为link
        dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
      };
      this.wxReady(wx, wxParam);
    },
    showVideos() {
      this.$router.push({
        name: "videoList",
        params: {
          id: this.id * 1,
        },
      });
    },
    doPayfordown() {
      const id = this.info.id;
      this.$http
        .post("e/?m=kf", {
          id,
        })
        .then((res) => {
          if (res.code === 0) {
            this.getData();
            this.dialogShow = false;
          }
        });
    },
  },
  created() {
    this.getData();
    this.$store.commit("setShowTabbar", false);
    this.showDowninfo = false;
  },
  beforeDestroy() {
    // 显示底部Tabbar
    this.$store.commit("setShowTabbar", true);
  },
  activated() {
    this.showDowninfo = false;
    this.$store.commit("setShowTabbar", false);
  },
  deactivated() {
    // 显示底部Tabbar
    this.$store.commit("setShowTabbar", true);
  },
};
</script>

<style>
.goods_img {
  min-height: 326px;
}

.desc {
  background-color: #ffffff;
  line-height: 24px;
  font-size: 14px;
  padding: 15px;
}

.desc img {
  max-width: 100%;
}

.collapse-title {
  color: #0a56c9;
}

.collapse-item {
  letter-spacing: 1.5px;
}

.van-dropdown-title {
  float: left;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-box-align: left;
  -webkit-align-items: left;
  align-items: left;
  -webkit-box-pack: left;
  -webkit-justify-content: left;
  justify-content: left;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  line-height: 48px;
  font-size: 14px;
}
</style>
